<template>
  <div>
    <iframe @load="loaded" class="iframe" id="myframe" src="/game/index.html" ref="iframe"
      :width="width" :height="height" frameborder="0" scrolling="auto" marginheight="0" marginwidth="0"></iframe>
  </div>
</template>
<script>
var qs = require("qs");
import wx from "weixin-js-sdk";
export default {
  name: 'PopupIframe',
  props: ['showIFrame'],
  data() {
    return {
      width: '',
      height: '',
      src: '',
      data: {
        num: 1
      },

    };
  },
  created() {
    this.width = window.screen.width;
    this.height = window.screen.height;
    window.addEventListener('message', (e) => {
      console.log('iframe传递过来的数据', e.data);
      if(e.data.hp_int == 0){//iframe返回结束
        this.$router.go(-1); 
      }
    }, false);

  },
  methods: {
    loaded() {
      // 只有在 iframe 加载时传递数据给子组件，$refs 才是非空的
      this.$refs.iframe.contentWindow.postMessage({
        type: 'myData',
        data: this.data,
      }, '*');
    },

  },
  filters: {
  },
};
</script>

<style scoped >
.iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: block;
}
</style>
  